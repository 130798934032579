
* {
  margin: 0;
  padding: 0;
}
#wrap {
  margin: 25px auto;
  width: 100%;
  padding: 10px;
}
#wrap2 {
  margin: 25px auto;
  width: 90%;
 
}
.title {
  color: #fff;
  text-align: center;
  margin: 10px;
}
.linkbutton {
  width: 250px;
  height: 100px;
  padding: 8px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background: #ecf0f1; */
   box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  cursor: pointer;
}

.linkbutton h2 {
  float: left;
  width: 350px;
  height: 65px;
  text-align: center;
  margin-top: 15px;
  font-weight: 600;
  text-transform: uppercase;
}
.linkbutton:hover {
  color: blue;
  background: none;
}
.linkbutton:hover .ease {
  width: 100%;
}
.ease {
  width: 0;
  background: #282828;
  height: 100px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.nohover:hover {
  color: #000;
}
/* Flat Ui Colors */
.turquoise {background: #1abc9c;}
.greensea {background: #16a085;}
.emerald {background: #2ecc71;}
.nephritis {background: #27ae60;}
.peterriver {background: #3498db;}
.belizehole {background: #2980b9;}
.amethyst {background: #9b59b6;}
.westeria {background: #8e44ad;}
.wetasphalt {background: #34495e;}
.midnightblue {background: #2c3e50;}
.sunflower {background: #f1c40f;}
.orange {background: #f39c12;}
.carrot {background: #e67e22;}
.pumpkin {background: #d35400;}
.alizarin {background: #e74c3c;}
.pomegranate {background: #c0392b;}
.clouds {background: #ecf0f1;}
.silver {background: #bdc3c7;}
.concrete {background: #95a5a6;}
.abestos {background: #7f8c8d;}

@media (max-width: 767px) {
  .linkbutton {
    width: 40%;
  }
  @media (max-width: 575px) {
    .linkbutton {
      width: 40%;
    }
    
   
  }
 
}