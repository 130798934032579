.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    hsl(253, 64%, 4%) 0%,
    #44107a 29%,
    #060304 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      font-size: 15px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}