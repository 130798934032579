.bg-section {
  background-size: cover;
  background-position: center, center;
}
.bg-section::before {
  content: "";

  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  top: 0;
  z-index: -1;
  left: 0;
}
