
  
  .texth2 {
   
    font-size: 50px;
    font-family: Helvetica;
    line-height: 0.9;
    letter-spacing: -0.02em;
    color: white;
  }
  .uniName{
    position: absolute;
    top: 30px;
  }
  
  .mask {
    height: 106px;
    position: relative;
    overflow: hidden;
    margin-top: var(--offset);
  }
  
  .mask span {
    display: block;
    box-sizing: border-box;
    position: absolute;
    top: 105px;
    padding-bottom: var(--offset);
    background-size: 100% 100%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-repeat: no-repeat;
  }
  
  .mask span[data-show] {
    transform: translateY(-100%);
    transition: .5s transform ease-in-out;
  }
  
  .mask span[data-up] {
    transform: translateY(-200%);
    transition: .5s transform ease-in-out;
  }
  
  .mask span:nth-child(1) {
    background-image: linear-gradient(45deg, #0ecffe 50%, #07a6f1);
  }
  
  .mask span:nth-child(2) {
    background-image: linear-gradient(45deg, #18e198 50%, #0ec15d);
  }
  
  .mask span:nth-child(3) {
    background-image: linear-gradient(45deg, #8a7cfb 50%, #633e9c);
  }
  
  .mask span:nth-child(4) {
    background-image: linear-gradient(45deg, #fa7671 50%, #f45f7f);
  }


  @media screen and (max-width: 768px) {
    .texth2 {
      font-size: 20px; /* Adjust font size for smaller screens */
      line-height: 1; /* Adjust line height for better readability */
      letter-spacing: -0.01em; /* Adjust letter spacing for smaller screens */
    }
  }